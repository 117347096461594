import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Avatar,
  Image,
  Button,
  Textarea,
  Divider,
  useToast, 
} from '@chakra-ui/react';
import { getProjectsByIds, getTeachersByIds, getStudentsByIds, fetchAnnouncementsForGroup, addCommentToGroupAnnouncement, addAnnouncementToGroup } from '../../../Services/Teacher/teacherService.tsx';
import { pusherClient } from '../../../pusherConfig.js';
import Chat from '../../chat/groupChat.tsx';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const GroupView = () => {
  const [projectDetails, setProjectDetails] = useState(null);
  const [groupDetails, setGroupDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teacherLoading, setTeacherLoading] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { projectId, groupId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [inputFromChat, setInputFromChat] = useState('');
  const currentLoggedInSId = sessionStorage.getItem("CurrentLoggedInSId").trim();
 
  const toast = useToast();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        setLoading(true);
        const data = await getProjectsByIds([projectId]);
        setProjectDetails(data[0]);
        const GD = data[0].groups.filter(group => {
            return group._id === groupId && group.studentIds.includes(currentLoggedInSId);
        });
        setGroupDetails(GD[0]);
      } catch (error) {
        console.error('Error fetching project details:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
      const at =  sessionStorage.getItem("activeTabInGroup");
    if(at){
      setActiveTab(parseInt(at));
    }
    };

    fetchProjectDetails();
  }, [projectId, groupId]);

  const clearPrefillMessage = () => {
    setInputFromChat(null);
  };

  useEffect(() => {
    if (location.state && location.state.prefillMessage) {
      console.log(location.state.prefillMessage);
      setInputFromChat(location.state.prefillMessage);
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, location.pathname, navigate]);

  const fetchTeachers = async (teacherIds) => {
    setTeacherLoading(true);
    try {
      const data = await getTeachersByIds(teacherIds);
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError(error.message);
    } finally {
      setTeacherLoading(false);
    }
  };


  const fetchStudents = async (studentIds) => {
    setStudentLoading(true);
    try {
      const data = await getStudentsByIds(studentIds);
      setStudents(data);
    } catch (error) {
      console.error('Error fetching students:', error);
      setError(error.message);
    } finally {
      setStudentLoading(false);
    }
  };


  useEffect(() => {
    if (activeTab === 1 && groupDetails) {
      fetchTeachers(groupDetails.teacherIds);
      fetchStudents(groupDetails.studentIds);
    }
  }, [activeTab, groupDetails]);

  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState('');
  const [newComment, setNewComment] = useState({});
  const [commentErrors, setCommentErrors] = useState({});
  const [showComments, setShowComments] = useState({});
  const [announcementError, setAnnouncementError] = useState('');
  const cleanPhotoURL = (url) => {
    const plink = url.replace(/['"]/g, '');  
    return plink;
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  useEffect(() => {
    if (!groupId) return;
  
    const announcementChannel = pusherClient.subscribe(`groupannouncements-${projectId}`);
  
    const handleNewAnnouncements = (newAnnouncements) => {
      const newAnnouncement = newAnnouncements.announcements[0]; 
      setAnnouncements(prevAnnouncements => {
        const announcementExists = prevAnnouncements.some(announcement => announcement._id === newAnnouncement._id);
        const updatedAnnouncements = announcementExists
          ? prevAnnouncements.map(announcement => 
              announcement._id === newAnnouncement._id ? newAnnouncement : announcement
            )
          : [...prevAnnouncements, newAnnouncement];
        const sortedData = updatedAnnouncements.sort((a, b) => new Date(b.time) - new Date(a.time));
  
        return sortedData;
      });
    };
  
    announcementChannel.bind('groupannouncements:new', handleNewAnnouncements);
  
    return () => {
      announcementChannel.unbind('groupannouncements:new', handleNewAnnouncements);
      pusherClient.unsubscribe(`groupannouncements-${projectId}`);
    };
  }, [projectId]);
  

  const handleCommentChange = (announcementId, value) => {
    setNewComment(prevState => ({
      ...prevState,
      [announcementId]: value
    }));
  };

  const toggleComments = (id) => {
    setShowComments(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  

  useEffect(() => {
    fetchAnnouncements();
  }, [groupId]);
  const fetchAnnouncements = async () => {
    try {
      const data = await fetchAnnouncementsForGroup(groupId);
      const sortedData = data.sort((a, b) => new Date(b.time) - new Date(a.time));
      setAnnouncements(sortedData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCommentSubmit = async (announcementId) => {
    const comment = newComment[announcementId] || '';
  
  if (comment.trim() === '') {
    setCommentErrors(prevState => ({
      ...prevState,
      [announcementId]: 'Comment cannot be empty.'
    }));
    setTimeout(() => {
      setCommentErrors(prevState => ({
        ...prevState,
        [announcementId]: ''
      }));
    }, 2000); 

    return;
  }

  
    try {
      await addCommentToGroupAnnouncement(announcementId, {
        userId: currentLoggedInSId,  
        comment: comment,
        userModel: "Student",
        projectId: projectId,
      });
      fetchAnnouncements(); 
      setNewAnnouncement(''); 
      setAnnouncementError(''); 
      setNewComment('');
      setCommentErrors({});
    } catch (error) {
      console.error('Error submitting comment:', error);
      
    }
  };

  const handleAnnouncementSubmit = async () => {
    if (newAnnouncement.trim() === '') {
      setAnnouncementError('Announcement cannot be empty.');
      setTimeout(() => {
        setAnnouncementError('');
      }, 2000); 
      return;
    }
  
    try {
      
      await addAnnouncementToGroup(groupId, {
        userId: currentLoggedInSId,  
        announcement: newAnnouncement,
        comments: [],
        userModel: "Student",
      });
      fetchAnnouncements(); 
      setNewAnnouncement(''); 
      setAnnouncementError(''); 
    } catch (error) {
      console.error('Error submitting announcement:', error);
      
    }
  };


  if (loading) {
    return (
      <Box>
        <LoadingSpinner/>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }
  const handleTabChange = (index) => {
    setActiveTab(index);
    sessionStorage.setItem("activeTabInGroup", index.toString());
  };
  return (
      <Box w={'full'}>
        <Tabs  index={activeTab} onChange={handleTabChange}>
          <TabList mb={2}>
            <Tab cursor={'pointer'}>Announcements</Tab>
            <Tab>People</Tab>
            <Tab>Chat</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box p={4} ml={-4}>
              <Box
                  position="relative"
                  w="full"
                  h="200px"
                  bg="gray.200"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={6}
                >
                  <Image
                    borderRadius="md"
                    objectFit="cover"
                    w="full"
                    h="full"
                    src={groupDetails?.url || ''}
                    alt={groupDetails?.groupName || ''}
                  />
                  <Box
                    position="absolute"
                    bottom={2}
                    left={2}
                    color="white"
                    fontWeight="bold"
                    fontSize="lg"
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Text>{groupDetails?.groupName || ''}</Text>
                    <Text fontSize="md">{groupDetails?.groupDescription || ''}</Text>
                  </Box>
                </Box>
                <Flex>
   
    <Box
      w="100%"
      p={4}
      bg="white"
    >
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg" boxShadow={"md"} bg="white">
        {/* <Heading size="md" mb={4}>Create Announcement</Heading> */}
        <Textarea
          placeholder="Write a new announcement..."
          value={newAnnouncement}
          onChange={(e) => {
            setNewAnnouncement(e.target.value);
            setAnnouncementError(''); 
          }}
          isInvalid={announcementError.length > 0}
          errorBorderColor="red.300"
        />
        <Text color="red.500" mt={2}>{announcementError}</Text>
        <Button mt={2} _hover={{ background: "purple.900", color: "white" }} onClick={handleAnnouncementSubmit} cursor={"pointer"}>Post</Button>
      </Box>

      <Box width="100%">
      <VStack spacing={4} align="start">
        {announcements.map(announcement => (
          <Box
            width="100%"
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            key={announcement._id}
            bg="white"
          >
            <Flex align="center" mb={2}>
              <Avatar src={cleanPhotoURL(announcement.user.photoURL)} size="sm" mr={2} />
              <Box>
                <Text fontSize="md" fontWeight="bold">
                  {announcement.user.name}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {formatDate(announcement.time)}
                </Text>
              </Box>
            </Flex>
            <Text fontSize="sm" fontWeight="normal">
              {announcement.announcement}
            </Text>
            <br />
            <Divider my={4} borderWidth={1} width="calc(100% + 2rem)" ml={-4} />
            {announcement.comments.length > 0 && (
              <Button
              mt={2}
              size={'md'}
              cursor="pointer"
              onClick={() => toggleComments(announcement._id)}
            >
              Class comments
            </Button>
            )}
            {showComments[announcement._id] && announcement.comments.length > 0 && (
              <VStack spacing={2} mt={2} align="start">
                {announcement.comments.map((comment, index) => (
                  <Box key={index} width="100%" mt={2}>
                  <Flex align="center" mb={2}>
                    <Avatar src={cleanPhotoURL(comment.user.photoURL)} size="sm" mr={2} />
                    <Box>
                    <Flex>
                      <Text fontSize="sm" fontWeight="bold">
                        {comment.user.name}
                      </Text>
                      <Text ml={2} fontSize="sm" color="gray.500">
                        {formatDate(comment.time)}
                      </Text>
                      </Flex>
                      <Text>{comment.comment}</Text>
                    </Box>
                  </Flex>
                </Box>
                ))}

              </VStack>
            )}
            <Box width="100%" mt={4}>
              <Textarea
                placeholder="Write a comment..."
                value={newComment[announcement._id] || ''}
                onChange={(e) => {
                  handleCommentChange(announcement._id, e.target.value);
                  setCommentErrors(prevState => ({
                    ...prevState,
                    [announcement._id]: ''
                  }));
                }}
                isInvalid={commentErrors[announcement._id]?.length > 0}
                errorBorderColor="red.300"
              />
              {commentErrors[announcement._id] && (
                <Text color="red.500" mt={2}>
                  {commentErrors[announcement._id]}
                </Text>
              )}
              <Button
                _hover={{ background: 'purple.900', color: 'white' }}
                mt={2}
                onClick={() => handleCommentSubmit(announcement._id)}
              >
                Comment
              </Button>
            </Box>
          </Box>
        ))}
      </VStack>
    </Box>


    </Box>
  </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box p={4}>
                <Heading size="md" mb={4}>People</Heading>
                <Tabs variant="soft-rounded" colorScheme="purple">
                  <TabList>
                    <Tab>Teachers</Tab>
                    <Tab>Students</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                    <VStack spacing={4} align="start">
      {teacherLoading ? (
         <LoadingSpinner/>
      ) : teachers.length === 0 ? (
        <Text>No teachers found.</Text>
      ) : (
        teachers.map((teacher) => (
          <Flex
            key={teacher._id}
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            align="center"
            width="full"
            justify="space-between" 
          >
            <Flex align="center">
              <Avatar name={teacher.name} src={teacher.photoURL} />
              <Box ml={4}>
                <Text fontWeight="bold">{teacher.name}</Text>
                <Text>{teacher.email}</Text>
              </Box>
            </Flex>
          </Flex>
        ))
      )}
    </VStack>
                    </TabPanel>
                    <TabPanel>
                    <VStack spacing={4} align="start">
                      {studentLoading ? (
                         <LoadingSpinner/>
                      ) : students.length === 0 ? (
                        <Text>No students found.</Text>
                      ) : (
                        students.map((student) => (
                          <Flex
                            key={student._id}
                            p={4}
                            borderWidth="1px"
                            borderRadius="lg"
                            align="center"
                            width="full"
                            justify="space-between" 
                          >
                            <Flex align="center">
                              <Avatar name={student.name} src={student.photoURL} />
                              <Box ml={4}>
                                <Text fontWeight="bold">{student.name}</Text>
                                <Text>{student.email}</Text>
                              </Box>
                            </Flex>
                          </Flex>
                        ))
                      )}
                    </VStack>

                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </TabPanel>
            <TabPanel><Chat inputFromChat={inputFromChat} clearPrefillMessage={clearPrefillMessage}/></TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
  );
};

export default GroupView;
