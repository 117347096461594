import React, { useEffect, useState } from 'react';
import { getClasses, getClassesByUserId } from '../../../Services/Teacher/teacherService.tsx';
import { Box, Spinner, Alert, AlertIcon, SimpleGrid, Text, Flex, Heading, Icon } from '@chakra-ui/react';
import { AiOutlineExclamationCircle } from 'react-icons/ai'; // Example icon
import ClassCard from '../../teacher-portal/HomeFrame/ClassCard.tsx';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const HomeFrame = () => {
  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const sId = sessionStorage.getItem("CurrentLoggedInSId").trim();

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClassesByUserId(sId,"student");
        setClasses(classesData);
      } catch (error) {
        console.error('Error fetching classes:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);
  const handleClassClick = (classItem, index) => {
    sessionStorage.setItem("activeClass", JSON.stringify(index)); 
    sessionStorage.setItem("activeNav",JSON.stringify(null));
    sessionStorage.setItem("activeProject",JSON.stringify(null));
    navigate(`/student-portal/class/${classItem._id}`); 
  };

  if (loading) {
    return (
      <Box>
       <LoadingSpinner/>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }


  if (classes.length === 0) {
    return (
        <Flex direction="column" alignItems="center" justifyContent="center" p={4}>
          <Box textAlign="center">
            <Icon as={AiOutlineExclamationCircle} boxSize="50px" color="gray.500" mb={4} />
            <Heading as="h3" size="md" mb={4} color="purple.900">
              No Classes Available
            </Heading>
          </Box>
        </Flex>
    );
  }

  return (
      <Flex direction="column" alignItems="start" mx="2" ml={-4}>
        <Text fontSize="xl" fontWeight="semibold" mx="2">
          Your Classes
        </Text>
        <Box p={3}>
          <SimpleGrid columns={[1, 2, 3]} spacing={2}>
          {classes?.map((classItem, index) => (
              <ClassCard key={classItem._id} classItem={classItem} index={index} onClick={handleClassClick} />
            ))}
          </SimpleGrid>
        </Box>
      </Flex>
  );
};

export default HomeFrame;
