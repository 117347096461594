import React, { useState, useEffect, useRef } from 'react';
import { getQuestionsFromGPT, getTopicsFromGPT } from '../../../Services/ChatGPT/Student/ChatGPTService.tsx';
import {getQuestionHistory, postQuestion} from '../../../Services/Student/studentService.tsx';
import { 
  Radio, RadioGroup, Stack, HStack, Button, Text, Box, Heading, Spinner, Select, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Flex, VStack,  ListItem, UnorderedList, IconButton, Textarea,Table, Thead, Tr, Th, Tbody, Td 
} from '@chakra-ui/react';
import {  FaHome } from 'react-icons/fa';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import * as mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import html2pdf from 'html2pdf.js';
import { ArrowBackIcon, ArrowForwardIcon, QuestionIcon } from '@chakra-ui/icons';
import { getClassesByUserId } from '../../../Services/Teacher/teacherService.tsx';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
const isProduction = process.env.NODE_ENV === 'production';

pdfjsLib.GlobalWorkerOptions.workerSrc = isProduction
  ? "https://unichat-redesigned.vercel.app/pdf.worker.min.mjs"
  : "/pdf.worker.min.mjs";

  const MAX_FILE_SIZE_MB = 5;


const LearnQ = ({ fileText, currentTId, setFileText, classId, setClassId, assignmentId, setAssignmentId}) => {
  const [selectedSubject, setSelectedSubject] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [difficulty, setDifficulty] = useState(1);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [quizEnded, setQuizEnded] = useState(false);  
  const [customSubject, setCustomSubject] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState("");
  const [topics, setTopics] = useState([]);
  const [selectedTopic,setSelectedTopic] = useState("");
  const boxRef = useRef(null); 
  const [questionHistory, setQuestionHistory] = useState({});
  const [questionCountForTopic, setQuestionCountForTopic] = useState({});
  const [currentTopicName, setCurrentTopicName] = useState(topics[0] || ""); 
  const currentLoggedInSId = sessionStorage.getItem("CurrentLoggedInSId").trim();
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [currentTeacherId, setCurrentTeacherId] = useState("");

  const scrollToBottom = () => {
    if (boxRef.current) {
      
      setTimeout(() => {
        boxRef.current.scrollTop = boxRef.current.scrollHeight; 
      }, 1000); 
    }
  };

  const user = sessionStorage.getItem('userType');
      const userId = user === 'student'
          ? sessionStorage.getItem('CurrentLoggedInSId').trim()
          : sessionStorage.getItem('CurrentLoggedInTId').trim();

  useEffect(() => {
    const fetchClasses = async () => {
      
      try {

        const classesData = await getClassesByUserId(userId,user);
        if(!classesData.message)
        setClasses(classesData);
        else
        setClasses([]);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
  
    fetchClasses();
  }, []);

  useEffect(() => {
    setCurrentTopicName(topics[0] || ""); 
}, [topics]);


  const fetchQuestion = async (subject, topic, difficulty, updatedHistory) => {
    setLoading(true);
    try {
        let response;
        if(updatedHistory)
      response = await getQuestionsFromGPT(subject, topic, difficulty, currentTeacherId || currentTId, updatedHistory[topic]);
    else
        response = await getQuestionsFromGPT(subject, topic, difficulty, currentTeacherId || currentTId);
      let trimmedResponse = response;
      if (response.startsWith("```")) {
        trimmedResponse = response.replace(/^```json|```$/g, "");
    }
      const { question, options, correct_answer } = JSON.parse(trimmedResponse);
      setCurrentQuestion({ question, options, correct_answer });
      setSelectedOption(null);
      setShowAnswer(false);
    } catch (error) {
        setSelectedOption(null);
      console.error("Error fetching question:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTopics = async (subject) => {
    setLoading(true);
    let response;
    let firstTopic = null; 
    try {
        response = await getTopicsFromGPT(subject,currentTeacherId || currentTId, currentLoggedInSId, classId || "", assignmentId || "");
        
        let trimmedResponse = response;
        
        if (response.startsWith("```")) {
            trimmedResponse = response.replace(/^```json|```$/g, "");
        }

        
        const parsedResponse = JSON.parse(trimmedResponse);
        const { topics } = parsedResponse; 
        setTopics(topics); 
        setSelectedTopic(topics[0]);
        firstTopic = topics[0] || null; 
    } catch (error) {
        setSelectedOption(null);
        console.error("Error fetching topics:", error);
    } finally {
        setLoading(false);
    }
    return firstTopic; 
};


  useEffect(() => {
    const fetchData = async () => {
      if (fileText) {
        let questions;
        let questionCount;
        try {
          const firstTopic = await fetchTopics(fileText);
          if (assignmentId && assignmentId !== "") {
            try {
              const response = await getQuestionHistory(currentLoggedInSId, classId, assignmentId, firstTopic);
              const { questions: fetchedQuestions, questionCount: fetchedQuestionCount } = response;
              questions = fetchedQuestions; 
              questionCount = fetchedQuestionCount;  
            } catch (error) {
              console.error('Error fetching question history:', error);
            }
          }
        
          setQuestionCountForTopic(prevCounts => ({
            ...prevCounts,
            [firstTopic]: questionCount
          }));
          await fetchQuestion(fileText, firstTopic, 1,questions);
        } catch (error) {
          console.error('Error fetching topics or questions:', error);
          
        }
      }
    };
  
    fetchData();
  }, [fileText]);

  const handleSubjectSelect = async (e) => {
    const subject = e.target.value;
    if (!subject) return;
  
    
    setSelectedSubject(subject);
    setDifficulty(1);
    setScore(0);
    setQuestionHistory({});
    setQuizEnded(false); 
    setSelectedFile(null);
    setFileData("");
  
    
    if (subject !== "Other" && subject !== "Upload" && subject!== "SRS") {
      try {
        const firstTopic = await fetchTopics(subject); 
        await fetchQuestion(subject, firstTopic, 1); 
      } catch (error) {
        console.error("Error fetching topics or questions:", error); 
      }
    }
  };
  


  const handleCustomSubjectSubmit = async () => {
    if (customSubject) {
      setSelectedSubject(customSubject);
      setCustomSubject('');
      setDifficulty(1);
      setScore(0);
      setQuestionHistory({});
      setQuizEnded(false); 
      const firstTopic = await fetchTopics(customSubject); 
      await fetchQuestion(customSubject, firstTopic, 1); 
    }
  };



  const handleAnswer = async () => {
    if (!currentQuestion) return;
    const trimmedSelected = selectedOption.trim();
    const trimmedCorrect = currentQuestion.correct_answer.trim();
    const selectedPrefix = trimmedSelected.slice(0, 2);
    const isCorrect = trimmedCorrect.includes(selectedPrefix);
    setShowAnswer(true);
    scrollToBottom();
  
    let updatedHistory;
    setQuestionHistory(prevHistory => {
      updatedHistory = { ...prevHistory };
    
      if (!updatedHistory[selectedTopic]) {
        updatedHistory[selectedTopic] = [];
      } else {
        updatedHistory[selectedTopic] = [...updatedHistory[selectedTopic]];
      }
  
      updatedHistory[selectedTopic].push({
        question: currentQuestion.question,
        options: currentQuestion.options,
        selectedOption,
        correctAnswer: currentQuestion.correct_answer,
        isCorrect,
      });
      
  
      return updatedHistory;
    });
    setQuestionCountForTopic(prevCounts => ({
      ...prevCounts,
      [selectedTopic]: (prevCounts[selectedTopic] || 0) + 1,  
  }));

    const questionData = {
        ...currentQuestion,
        selectedOption,
        isCorrect,
        topic: selectedTopic,  
      };
      if(classId!=="" && assignmentId!="")
        await postQuestion(questionData, currentLoggedInSId, classId, assignmentId);
    
  
    if (isCorrect) {
      setScore(score + 1);
      setDifficulty(difficulty + 1);
    } else {
      setDifficulty(Math.max(1, difficulty - 1));
    }
  
    setTimeout(async () => {
      if (fileText) {
        await fetchQuestion(fileText, selectedTopic, difficulty + 1, updatedHistory);
      } else if (fileData) {
        await fetchQuestion(fileData, selectedTopic, difficulty + 1, updatedHistory);
      } else {
        await fetchQuestion(selectedSubject, selectedTopic, difficulty + 1, updatedHistory);
      }
    }, 3000);
  };

  
  const handleEndQuiz = () => setShowEndModal(true);

  const handleConfirmEndQuiz = () => {
    setQuizEnded(true);
    setSelectedTopic("");
    setShowEndModal(false);
  };

  const handleBack = () => {
    setQuizEnded(false);
    setSelectedTopic(topics[0]);
  }

  
  const handleHome = () => {
    setSelectedSubject("");
    setCurrentQuestion(null);
    setQuizEnded(false);
    setDifficulty(1);
    setScore(0);
    setFileText(null);
    setAssignmentId("");
    setSelectedFile(null);
    setFileData("");
    setQuestionHistory({});
    setSelectedTopic("");
    setTopics([]);
    setAvailable(false);
  }


  const renderContent = (content) => {
    
    const codeBlockRegex = /```([a-zA-Z]*)\n?([\s\S]*?)```|```([^`]+)```/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = codeBlockRegex.exec(content)) !== null) {
      const [fullMatch, language, code] = match;
      const startIndex = match.index;

      if (startIndex > lastIndex) {
        parts.push(<Text key={`text-${lastIndex}`}>{content.slice(lastIndex, startIndex)}</Text>);
      }

      parts.push(
        <SyntaxHighlighter key={`code-${startIndex}`} language={language || 'javascript'} style={materialDark}>
          {code.trim()}
        </SyntaxHighlighter>
      );
      lastIndex = codeBlockRegex.lastIndex;
    }

    if (lastIndex < content.length) {
      parts.push(<Text key={`text-${lastIndex}`}>{content.slice(lastIndex)}</Text>);
    }

    return parts;
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleTopicClick = async (topic, index) => {
    setSelectedTopic(topic);
    
    let questions = []; 
    let questionCount = 0;  
  
    if (assignmentId && assignmentId !== "") {
      try {
        const response = await getQuestionHistory(currentLoggedInSId, classId, assignmentId, topic);
        const { questions: fetchedQuestions, questionCount: fetchedQuestionCount } = response;
        questions = fetchedQuestions; 
        questionCount = fetchedQuestionCount;  
      } catch (error) {
        console.error('Error fetching question history:', error);
      }
    }
  
    setCurrentTopicName(topic);
    setQuestionCountForTopic(prevCounts => ({
      ...prevCounts,
      [topic]: questionCount
    }));
  
    fetchQuestion(fileText, topic, 1, questions);
  };
  
  
  
  const extractFileData = async (file) => {
  
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      alert(`File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`);
      return null;
    }
  
    const fileType = file.type;
  
    if (fileType.includes('pdf')) {
      
      const pdfData = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
      let text = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const pageText = await page.getTextContent();
        text += pageText.items.map(item => item.str).join(' ');
      }
      return text;
    } else if (fileType.includes('word') || fileType.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return result.value; 
    } else if (fileType.includes('text/plain')) {
      
      const textData = await file.text();
      return textData;
    } else {
      alert('Unsupported file type.');
      return null;
    }
  };

  const handleFileUpload = async() => {
    let fileData;
    let firstTopic;
    if(selectedFile){
        try{
            fileData = await extractFileData(selectedFile);
            setFileData(fileData)
        }catch (error) {
            console.error("Error reading the file:", error);  
        
          }finally{
        setSelectedSubject("Learn with AI");
        setCustomSubject('');
        setDifficulty(1);
        setScore(0);
        setQuestionHistory({});
        setQuizEnded(false); 
        if(fileData)
        firstTopic = await fetchTopics(fileData);
        fetchQuestion(fileData, firstTopic, 1);
        }
    }
  }
  const [available, setAvailable] = useState(false);
  
  

  const generatePDF = () => {
    const element = document.getElementById("quiz-summary");
    const options = {
        margin: 0.5,
        filename: 'quiz_summary.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { 
            unit: 'in', 
            format: [10, 11], 
            orientation: 'portrait' 
        }
    };

    html2pdf().from(element).set(options).save();
};


const [isChatOpen, setIsChatOpen] = useState(false);
const handleOpenChat = () => {
    setIsChatOpen(true);
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  const handleClassSelect = (e) => {
    setClassId("");
    setSelectedClass(e.target.value);
    const selectedClass = classes.find((classItem) => classItem._id === e.target.value);
    setCurrentTeacherId(selectedClass?.teachers[0]);
  };

return (
    <>
      <Box w="full" h="84vh" p={4} borderRadius="xl" bg="white">
        <Flex h="100%">
          {/* Left Sidebar */}
          
          {(fileData || fileText || (selectedSubject && selectedSubject !== "Other" && selectedSubject !== "Upload" && selectedSubject !== "SRS") ) && (
            <Box 
                w="27%" 
                h="100%" 
                p={4} 
                borderRadius="xl" 
                boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" 
                mr={3} 
                overflowY="auto" 
                overflowX="hidden" 
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none', 
                    },
                    '-ms-overflow-style': 'none', 
                    'scrollbar-width': 'none', 
                }}
            >
                <VStack align="start" spacing={8}>
    <Text fontSize="lg" textDecoration="underline" fontWeight="bold">Topics</Text>
    <UnorderedList spacing={2}>
        {topics.map((topicName, index) => (
          <ListItem key={index} fontSize="md">
            <Box 
              as="button"
              onClick={() => { 
                  handleTopicClick(topicName);
              }} 
              width="100%" 
              textAlign="left" 
              padding="2" 
              borderRadius="md" 
              bg={currentTopicName === topicName ? 'gray.200' : 'transparent'} 
              cursor={'pointer'} 
            >
              {topicName} 
              {currentTopicName === topicName && 
          ` (${questionCountForTopic[topicName] || 0})`}
            </Box>
          </ListItem>
        ))}
      </UnorderedList>


</VStack>

            </Box>
        )}
  
          {/* Right Quiz Content */}
          <Box 
                w={fileData || fileText || (selectedSubject && selectedSubject !== "Other" && selectedSubject !== "Upload" && selectedSubject !== "SRS") ? "73%" : "100%"}
                h="100%" 
                p={4} 
                borderRadius="xl" 
                boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" 
                mr={3} 
                overflowY="auto" 
                overflowX="hidden" 
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none', 
                    },
                    '-ms-overflow-style': 'none', 
                    'scrollbar-width': 'none', 
                }}
            >
            <FaHome
              size="24" 
              cursor="pointer" 
              onClick={handleHome} 
              
            />
  
        <VStack spacing={4}>
            <Heading mb={2} mt={-2} color={"purple.900"} textAlign="center">
                {selectedSubject || 'Learn with AI'}
            </Heading>
            { (fileText ||(selectedSubject && selectedSubject!=="Other" && selectedSubject!=="Upload" && selectedSubject!=="SRS")) &&(
            <Text color="gray.600" textAlign="center">
               
            </Text>
            )}
        </VStack>
            {loading ? (
              <Box textAlign="center">
                <LoadingSpinner/>
              </Box>
            ) : (!selectedSubject || selectedSubject === "Other" || selectedSubject === "Upload" || selectedSubject === "SRS") && !fileText ? (
                <>
                <HStack mt={6} mb={4} justifyContent="center" spacing={8}>
                <Text>Select a class:</Text>
                <Select 
                    width="200px" 
                    value={selectedClass} 
                    onChange={handleClassSelect} 
                    cursor="pointer"
                >
                    <option value="">Choose class</option>
                    {classes.map((classItem) => (
                    <option key={classItem._id} value={classItem._id}>
                        {classItem.classname}
                    </option>
                    ))}
                </Select>
                </HStack>
                  <HStack mt={6} mb={4} justifyContent="center" spacing={4}>
                    <Text>Select a subject:</Text>
                    <Select width="200px" value={selectedSubject} onChange={handleSubjectSelect} disabled={!selectedClass} cursor="pointer">
                    <option value="">Choose subject</option>
                      <option value="Upload">Upload a file</option>
                      <option value="React">React</option>
                      <option value="JavaScript">JavaScript</option>
                      <option value="CSS">CSS</option>
                      <option value="Maths">Mathematics</option>
                      <option value="Other">Other</option>
                    </Select>
                  </HStack>
                  {selectedSubject === "Other" && (
                    <HStack mt={8} mb={4} justifyContent="center">
                      <Input
                        placeholder="Enter custom subject" 
                        value={customSubject}
                        onChange={(e) => setCustomSubject(e.target.value)} 
                        width="200px"
                      />
                      <Button 
                        bg={'purple.900'}
                        color={'white'}
                        _hover={{bg:"purple.900"}}
                        onClick={handleCustomSubjectSubmit}
                        isDisabled={!customSubject}
                      >
                        Submit
                      </Button>
                    </HStack>
                  )}
                  {selectedSubject === "Upload" && (
                    <HStack mt={8} mb={4} justifyContent="center">
                      <Box 
                        border="1px" 
                        borderColor="gray.300" 
                        p={4} 
                        textAlign="center" 
                        cursor="pointer"
                        borderRadius="md"
                        _hover={{ borderColor: 'gray.400' }}
                        mb={4}
                      >
                        <Input
                          type="file"
                          onChange={handleFileSelect}
                          accept=".docx,.pdf"
                          display="none" 
                          id="file-input"
                        />
                        <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                          {selectedFile ? (
                            <Text>{selectedFile.name}</Text>
                          ) : (
                            <Text>Drag 'n' drop a file here, or click to select one</Text>
                          )}
                        </label>
                      </Box>
                      <Button 
                        bg={'purple.900'}
                        color={'white'}
                        _hover={{bg:"purple.900"}}
                        onClick={handleFileUpload}
                        isDisabled={!selectedFile}
                        mb={4}
                      >
                        Submit
                      </Button>
                    </HStack>
                  )}
                </>
              ) : (
                <Box>
                  <Flex justifyContent="space-between" alignItems="center" mb={8}>
                    
                    {quizEnded && (
                        <>
                        <Box>
                        <IconButton
                            aria-label="Go back"
                            icon={<ArrowBackIcon />}
                            onClick={handleBack}
                            bg={'purple.900'}
                            color={'white'}
                            _hover={{bg:'purple.900'}}
                            size="sm"
                            
                        />
                    </Box>
                      <Button 
                        onClick={generatePDF}
                        bg={'purple.900'}
                        color={'white'}
                        _hover={{bg:'purple.900'}}
                      >
                        Generate PDF
                      </Button>
                      </>
                    )}
                  </Flex>
                  
  
                  {!currentQuestion ? (
                    <Text>No more questions available.</Text>
                  ) : quizEnded ? (
                    <Box id="quiz-summary">
                      <Heading fontSize="lg" mb={4}>Quiz Summary</Heading>
                      {Object.entries(questionHistory).map(([topicTitle, questions], topicIndex) => (
                        <Box key={topicIndex} mb={6}>
                          <Heading fontSize="md" mb={4}>{topicTitle}</Heading> {/* Display topic title */}
                          {questions.map((item, questionIndex) => (
                            <Box key={questionIndex} mb={4} p={4} borderWidth="1px" borderRadius="md">
                              <Text fontSize="lg" mb={2}>
                                <strong>Question {questionIndex + 1}:</strong> {renderContent(item.question)}
                              </Text>
                              <Text mb={1}><strong>Options:</strong></Text>
                              {item.options.map((option, index) => (
                                <Text key={index}>{renderContent(option)}</Text>
                              ))}
                              <Text mb={1} color={item.isCorrect ? 'green.500' : 'red.500'}>
                                Your answer: {renderContent(item.selectedOption)} {item.isCorrect ? "(Correct)" : "(Wrong)"}
                              </Text>
                              {!item.isCorrect && (
                                <Text>Correct answer: {renderContent(item.correctAnswer)}</Text>
                              )}
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box ref={boxRef}>
                      <Text fontSize="xl" mb={4}>{renderContent(currentQuestion.question)}</Text>
                      <RadioGroup onChange={setSelectedOption} value={selectedOption}>
                        <Stack spacing={4}>
                          {currentQuestion.options.map((option, idx) => (
                            <Radio key={idx} value={option} cursor="pointer">
                              {renderContent(option)}
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                      <HStack justify="space-between" mt={16}>
                        <Button 
                          bg="purple.900" 
                          onClick={handleAnswer}
                          isDisabled={!selectedOption} 
                          color="white"
                          cursor="pointer"
                          _hover={{bg:"purple.900"}}
                        >
                          Submit
                        </Button>
                        <Button 
                          onClick={handleEndQuiz} 
                          cursor="pointer"
                          color={'purple.900'}
                        >
                          End Quiz
                        </Button>
                      </HStack>
  
                      {showAnswer && (
                        <>
                          <Text 
                            mt={4} 
                            fontSize="lg" 
                            color={selectedOption === currentQuestion.correct_answer ? 'green.500' : 'red.500'}
                          >
                            {selectedOption === currentQuestion.correct_answer ? 'Correct Answer!' : 'Wrong Answer!'}
                          </Text>
  
                          {selectedOption !== currentQuestion.correct_answer && (
                            <Text mt={2} color="purple.900" fontSize="lg">
                              Correct answer is: {renderContent(currentQuestion.correct_answer)}
                            </Text>
                          )}
                        </>
                      )}
                      
                    </Box>
                  )}
                </Box>
              )}
          </Box>
        </Flex>
  
        {/* End Quiz Confirmation Modal */}
        <Modal isOpen={showEndModal} onClose={() => setShowEndModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>End Quiz</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to end the quiz?
            </ModalBody>
            <ModalFooter>
              <Button color={'purple.900'} mr={3} onClick={() => setShowEndModal(false)}>No</Button>
              <Button bg={'purple.900'} color={"white"} _hover={{bg:"purple.900"}} onClick={handleConfirmEndQuiz}>Yes</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
                          }
export default LearnQ;  