import React, { useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";
import ViewProjectChatHistory from "./ViewProjectChatHistory.tsx";
import ViewGroupChatHistory from "./ViewGroupChatHistory.tsx";

const Message = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
      <Box height="90%" width={'full'}>
        <Tabs  index={selectedTab} onChange={(index) => setSelectedTab(index)}>
          <TabList mb="2">
            <Tab>View Project Chat History</Tab>
            <Tab>View Group Chat History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ViewProjectChatHistory />
            </TabPanel>
            <TabPanel>
              <ViewGroupChatHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
  );
};

export default Message;
