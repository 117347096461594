import {
    Box,
    Flex,
    useColorModeValue,
    VStack,
    Spinner,
    Text,
    Avatar
  } from '@chakra-ui/react';
  import {
    Home,
    PlusCircle,
    MessageCircle,
    Bot,
    HardDrive,
    BookOpen,
    Settings,
    Folder,
    Users,
    UserSquare2,
    Loader,
    Brain,
    Code2,
  } from 'lucide-react';
  import { useLocation } from 'react-router-dom';
  import { SidebarItem } from './SidebarItem.tsx';
  import { SidebarSection } from './SidebarSection.tsx';
  import { useState, useEffect } from 'react';
  import React from 'react';
  import { LoadingSpinner } from '../../commons/LoadingSpinner.tsx';
  import useDrivePicker from 'google-drive-picker';
  import CreateClass from "../teacher-portal/CreateClass/CreateClass.tsx";
  import CreateProject from "../teacher-portal/CreateProject/CreateProject.tsx";
  import CreateGroup from "../teacher-portal/CreateGroup/CreateGroup.tsx";
  import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
  import { app } from "../../config.js"; 
  import giimage from '../../Assets/G-Ideas.png'
  
  const STAGGER_DELAY = 0.1;
  
  export const SideNav = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [sidebarData, setSidebarData] = useState(null);
    const [classesOpen, setClassesOpen] = useState(true);
    const [projectsOpen, setProjectsOpen] = useState(true);
    const [groupsOpen, setGroupsOpen] = useState(true);
    const [showCreateClassModal, setShowCreateClassModal] = useState<boolean>(false);
    const [showCreateProjectModal, setShowCreateProjectModal] = useState<boolean>(false);
    const [showCreateGroupModal, setShowCreateGroupModal] = useState<boolean>(false);
  
    const bg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
  
    const pathParts = location.pathname.split('/').filter(Boolean);
    const isInClass = pathParts[1] === 'class';
    const isInProject = pathParts[3] === 'project';
    const isInGroup = pathParts[5] === 'group';
  
    const currentClassId = isInClass ? pathParts[2] : null;
    const currentProjectId = isInProject ? pathParts[4] : null;
    const userType = sessionStorage.getItem("userType");
    let goto;
    if(userType == "teacher")
      goto = "teacher-portal"
    else if(userType == "student")
      goto = "student-portal"
    
  
      useEffect(() => {
        const fetchSidebarData = () => {
          const data = JSON.parse(sessionStorage.getItem('sideBarData'));
          if (!isInClass) {
            sessionStorage.removeItem("currentClassId");
            sessionStorage.removeItem("currentProjectId");
          }
          if (!isInProject) {
            sessionStorage.removeItem("currentProjectId");
          }
          if (data) {
            setSidebarData(data);
          }
          setIsLoading(false);
        };
        fetchSidebarData();
        const handleSidebarUpdate = () => fetchSidebarData();
        window.addEventListener('sidebarDataUpdated', handleSidebarUpdate);
        return () => {
          window.removeEventListener('sidebarDataUpdated', handleSidebarUpdate);
        };
      }, [location.pathname]);
      
      
  
    const currentProjects = sidebarData?.classes
      ?.find(cls => cls._id === currentClassId)
      ?.projects || [];
  
    const currentGroups = currentProjects
      ?.find(project => project._id === currentProjectId)
      ?.groups || [];
  
    const currentPath = location.pathname;
  
    const [openPicker] = useDrivePicker();
  
      
    const handleOpenPicker = async() => {
      let token;
      const auth = getAuth(app);
      
      let studentTokenExpirationTime;
      let TokenExpirationTime;
      let expirationTime;
  
      if(userType == "student"){
        token = sessionStorage.getItem("studentGoogleAccessToken");
        studentTokenExpirationTime = sessionStorage.getItem("studentTokenExpirationTime");
        expirationTime = new Date(studentTokenExpirationTime).getTime();
      }
      else if(userType == "teacher"){
        TokenExpirationTime = sessionStorage.getItem("TokenExpirationTime");
        expirationTime = new Date(TokenExpirationTime).getTime();
        token = sessionStorage.getItem("googleAccessToken");
      }
     
      if (!token) {
        console.error("No Google Access Token found");
        return;
      }
  
  
      const currentTime = Date.now(); 
      
      const timeLeft = expirationTime - currentTime;
  
      console.log(`Access token expires in ${timeLeft / 1000} seconds`);
  
      if (timeLeft < 5 * 60 * 1000 && user) {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: "select_account" });
        provider.addScope("https://www.googleapis.com/auth/drive");
        provider.addScope("https://www.googleapis.com/auth/drive.file");
        provider.addScope("https://www.googleapis.com/auth/documents");
        const result = await signInWithPopup(auth, provider);
        const loggedInUser = result.user;
        const credential = GoogleAuthProvider.credentialFromResult(result);
        token = credential.accessToken;
        const accessToken = await loggedInUser.getIdTokenResult(true);
        if(userType == "student"){
        sessionStorage.setItem("studentGoogleAccessToken", token);
        sessionStorage.setItem("studentTokenExpirationTime", accessToken.expirationTime);
        }
        else if(userType == "teacher"){
          sessionStorage.setItem("googleAccessToken", token);
          sessionStorage.setItem("TokenExpirationTime", accessToken.expirationTime);
        }
      }
      
      
  
      const customViewShared = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
        .setOwnedByMe(false) 
        .setIncludeFolders(true); 
      const customViewOwned = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
        .setOwnedByMe(true) 
        .setIncludeFolders(true); 
  
      openPicker({
        clientId: process.env.REACT_APP_DRIVE_CLIENTID,
        developerKey: process.env.REACT_APP_DRIVE_DEVELOPERKEY,
        token: token,
        disableDefaultView:true,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true,
        customViews: [customViewShared, customViewOwned],
        callbackFunction: (data) => {
          if (data.action === "picked") {
            const doc = data.docs[0];
            const docUrl = doc.embedUrl || doc.url; 
      
            
            sessionStorage.setItem("selectedDoc", docUrl);
            sessionStorage.setItem("selectedDocId", doc.id);
      
            
            window.open(docUrl, '_blank');
          }
          
        },
      });
      
    };
  
    const handleCreateClassClose = () => {
      setShowCreateClassModal(false);
    };
  
    const handleCreateProjectClose = () => {
      setShowCreateProjectModal(false);
    };
  
    const handleCreateGroupClose = () => {
      setShowCreateGroupModal(false);
    };
  
    const handleCreateClick = () => {
      if(isInProject){
        setShowCreateGroupModal(true);
      }
      else if(isInClass){
        setShowCreateProjectModal(true);
      }
      else{
        setShowCreateClassModal(true);
      }
    }
  
    // let height = currentPath.includes('messaging') ? '118%' : '114%';
    // height = currentPath.includes('gpthistory')?'136%':'114%'
  
    return (
      <Box
        w="260px"
        bg={bg}
        borderRight="1px"
        borderColor={borderColor}
        h={'100vh'}
        pos="fixed"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
              display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
      }}
        sx={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.300',
            borderRadius: '24px',
          },
        }}
      >
        <Flex 
      mt={4}
      mb={2} 
      ml={4} 
      align="center" 
      gap={2} 
      position="sticky" 
      top="0" 
      zIndex="1" 
      bg={bg} 
      pl={2}
      pr={2}
      pt={2} 
    >
       <Avatar src={giimage} h={10} w={10}/>
      <Text fontSize="xl" fontWeight="bold">
        Generative Ideas
      </Text>
    </Flex>
        <VStack spacing={0} align="stretch" pt="4">
        
          <SidebarItem icon={Home} to={`/${goto}/homeframe`} delay={STAGGER_DELAY * 1}>
            Home
          </SidebarItem>
  
          {!isInGroup && userType === "teacher" && (
            <SidebarItem 
              icon={PlusCircle} 
              onClick={handleCreateClick}
              delay={STAGGER_DELAY * 2}
            >
              {isInProject ? 'Create Group' :
               isInClass ? 'Create Project' :
               'Create Class'}
            </SidebarItem>
          )}
  
          <SidebarSection
            title="YOUR CLASSES"
            isOpen={classesOpen}
            onToggle={() => setClassesOpen(!classesOpen)}
            isCollapsible
            maxHeight="200px"
            delay={STAGGER_DELAY * 3}
          >
            {isLoading ? (
              <Flex justify="center" py="4">
                <LoadingSpinner />
              </Flex>
            ) : (
              sidebarData?.classes?.map((cls, index) => (
                <SidebarItem
                  key={cls._id}
                  icon={Folder}
                  to={`/${goto}/class/${cls._id}`}
                  isNested
                  delay={STAGGER_DELAY * (4 + index)}
                >
                  {cls.classname}
                </SidebarItem>
              ))
            )}
          </SidebarSection>
  
          {isInClass && currentProjects.length > 0 && (
            <SidebarSection
              title="PROJECTS"
              isOpen={projectsOpen}
              onToggle={() => setProjectsOpen(!projectsOpen)}
              isCollapsible
              maxHeight="200px"
              delay={STAGGER_DELAY * 3}
            >
              {currentProjects.map((project, index) => (
                <SidebarItem
                  key={project._id}
                  icon={Users}
                  to={`/${goto}/class/${currentClassId}/project/${project._id}`}
                  isNested
                  delay={STAGGER_DELAY * (8 + index)}
                >
                  {project.projectName}
                </SidebarItem>
              ))}
            </SidebarSection>
          )}
  
          {isInProject && currentGroups.length > 0 && (
            <SidebarSection
              title="GROUPS"
              isOpen={groupsOpen}
              onToggle={() => setGroupsOpen(!groupsOpen)}
              isCollapsible
              maxHeight="200px"
              delay={STAGGER_DELAY * 3}
            >
              {currentGroups.map((group, index) => (
                <SidebarItem
                  key={group._id}
                  icon={UserSquare2}
                  to={`/${goto}/class/${currentClassId}/project/${currentProjectId}/group/${group._id}`}
                  isNested
                  delay={STAGGER_DELAY * (11 + index)}
                >
                  {group.groupName}
                </SidebarItem>
              ))}
            </SidebarSection>
          )}
  
          <Box>
            <SidebarSection
              title="TOOLS"
              isOpen={true}
              onToggle={() => {}}
              delay={STAGGER_DELAY * 7}
            >
              <SidebarItem icon={MessageCircle} to={`/${goto}/messaging`} delay={STAGGER_DELAY * 10}>
              Chat History
            </SidebarItem>
            <SidebarItem icon={Bot} to={`/${goto}/gpthistory`} delay={STAGGER_DELAY * 11}>
              ChatGPT History
            </SidebarItem>
            <SidebarItem 
              icon={HardDrive}  
              delay={STAGGER_DELAY * 12}
              onClick={handleOpenPicker} 
            >
              Google Drive
            </SidebarItem>
            <SidebarItem icon={BookOpen} to={`/${goto}/learnq`} delay={STAGGER_DELAY * 13}>
              LearnQ
            </SidebarItem>
            {/* <SidebarItem icon={Code2} to={`/learncode`} delay={STAGGER_DELAY * 13}>
              LearnCode
            </SidebarItem> */}
            {userType === "teacher" && (
            <SidebarItem icon={Settings} to={`/${goto}/config`} delay={STAGGER_DELAY * 14}>
              Config
            </SidebarItem>
            )}
  
            </SidebarSection>
          </Box>
        </VStack>
        <CreateClass
              isOpen={showCreateClassModal}
              onClose={() => handleCreateClassClose()}
          />
          <CreateProject
              isOpen={showCreateProjectModal}
              onClose={() => handleCreateProjectClose()}
          />
          <CreateGroup
              isOpen={showCreateGroupModal}
              onClose={() => handleCreateGroupClose()}
          />
      </Box>
    );
  };
  