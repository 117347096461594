import { axiosInstance } from '../../axiosUtils.ts';


// Send User Data
export const sendUserData = async (userData) => {
  try {
    const data = await axiosInstance.post('/api/student', userData);
    return data.data;
  } catch (error) {
    console.error('Error sending user data:', error);
    throw error;
  }
};

// Get Student by Email
export const getStudentByEmail = async (email) => {
  try {
    const response = await axiosInstance.get(`/api/student/${email}`);
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return [];
    }
    console.error('Error fetching student:', error);
    throw error;
  }
};

// Delete Student from Class Projects
export const deleteStudentFromClassProjects = async (projectIds, studentId) => {
  try {
    const response = await axiosInstance.post('/api/projects/remove-student', {
      projectIds,
      studentId,
    });
    return response.data;
  } catch (error) {
    console.error('Error removing student from projects:', error);
    throw new Error('Failed to remove student from projects');
  }
};

// Delete Student from Class
export const deleteStudentFromClass = async (classId, studentId, token) => {
  try {
    const response = await axiosInstance.post(`/api/classes/${classId}/remove-student`, {
      studentId,
      token
    });
    return response.data;
  } catch (error) {
    console.error('Error removing student from class:', error);
    throw new Error('Failed to remove student from class');
  }
};

// Delete Student from Group
export const deleteStudentFromGroup = async (groupId, studentId) => {
  try {
    const response = await axiosInstance.post(`/api/groups/${groupId}/remove-student`, {
      studentId,
    });
    return response.data;
  } catch (error) {
    console.error('Error removing student from Group:', error);
    throw new Error('Failed to remove student from Group');
  }
};

// Post Question
export const postQuestion = async (questionData, studentId, classId, assignmentId) => {
  try {
    const response = await axiosInstance.post('/api/addQuestion/', {
      questionData,  
      studentId,
      classId,  
      assignmentId
    });
    return response.data;
  } catch (error) {
    console.error('Error posting question:', error);
    throw new Error('Failed to post the question');
  }
};

export const getQuestionHistory = async (studentId, classId, assignmentId, topic) => {
  try {
    const response = await axiosInstance.post('/api/student/getQuestionHistory', {
      studentId,
      classId,
      assignmentId,
      topic,
    });
    return response.data; 
  } catch (error) {
    console.error('Error fetching question history:', error);
    throw new Error('Failed to fetch question history');
  }
};


// Post SRS Data
export const postSRSData = async (projectId, projectDescription, srsData, studentId, isNonFunctionalRequired) => {
  try {
    const response = await axiosInstance.post('/api/postsrstodb/', {
      projectId,
      projectDescription,
      srsData,
      studentId,
      isNonFunctionalRequired,
    });
    return response.data;
  } catch (error) {
    console.error('Error posting SRS data:', error);
    throw new Error('Failed to post the SRS data');
  }
};

// Get Questions by Student ID
export const getQuestionsByStudentId = async (studentId) => {
  try {
    const response = await axiosInstance.get(`/api/getQuestions/${studentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw new Error('Failed to fetch questions');
  }
};

// Fetch Assignments for Student
export const fetchAssignmentsForStudent = async (studentId) => {
  try {
    const response = await axiosInstance.get(`/api/assignments/student/${studentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching assignments for students', error);
    throw new Error('Failed to fetch assignments');
  }
};

// Fetch SRS for Project
export const fetchSRSforProject = async (projectId) => {
  try {
    const response = await axiosInstance.get(`/api/srs/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching SRS for project:', error);
    throw new Error('Failed to fetch SRS for project');
  }
};

// Update Student Profile
export const updateStudentProfile = async (userId, userData) => {
  try {
    const response = await axiosInstance.put(`/api/student/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error('Error updating Student profile:', error);
    throw new Error('Failed to update Student profile');
  }
};
