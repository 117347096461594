import React, { useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";
import ViewProjectChatGPTHistory from "./ViewProjectChatGPTHistory.tsx";
import ViewGroupChatGPTHistory from "./ViewGroupChatGPTHistory.tsx";
import ViewPrivateChatGPTHistory from "./ViewPrivateChatGPTHistory.tsx";

const Message = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
      <Box height="90%" width={'full'}>
        <Tabs  index={selectedTab} onChange={(index) => setSelectedTab(index)}>
          <TabList>
          <Tab>Private History</Tab>
            <Tab>Project History</Tab>
            <Tab>Group History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
            <ViewPrivateChatGPTHistory />
            </TabPanel>
            <TabPanel>
              <ViewProjectChatGPTHistory />
            </TabPanel>
            <TabPanel>
              <ViewGroupChatGPTHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
  );
};

export default Message;
