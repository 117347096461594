import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { LucideIcon } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import React from 'react';

const MotionFlex = motion(Flex);

interface SidebarItemProps {
  icon: LucideIcon;
  children: React.ReactNode;
  to?: string;
  isNested?: boolean;
  delay?: number;
  onClick?: () => void;
}

export const SidebarItem = ({ icon, children, to, isNested = false, delay = 0, onClick  }: SidebarItemProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const activeBg = useColorModeValue('blue.50', 'blue.900');
  const hoverBg = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent) => {
    if (onClick) {
      event.preventDefault(); 
      onClick();
    }
    else if(to){
      if(to == "/learncode")
      window.open(to, "_blank");
      else
      navigate(to);
    }
  };

  return (
    <MotionFlex
      align="center"
      p="4"
      mx={isNested ? "4" : "0"}
      borderRadius="lg"
      role="group"
      cursor="pointer"
      position="relative"
      onClick={handleClick}
      _hover={{
        bg: hoverBg,
        transform: 'translateX(4px)',
      }}
      bg={isActive ? activeBg : 'transparent'}
      color={textColor}
      transition="all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3, delay }}
    >
      <Icon
        mr="4"
        fontSize="16"
        as={icon}
        transition="all 0.2s"
        _groupHover={{ 
          color: 'blue.500',
          transform: 'scale(1.1)'
        }}
        color={isActive ? 'blue.500' : 'gray.500'}
      />
      <Text 
        fontSize="sm" 
        fontWeight={isActive ? "medium" : "normal"}
        transition="all 0.2s"
        _groupHover={{ 
          color: 'blue.600',
          fontWeight: "medium"
        }}
      >
        {children}
      </Text>
      {isActive && (
        <Box
          position="absolute"
          left="0"
          top="50%"
          transform="translateY(-50%)"
          w="3px"
          h="60%"
          bg="blue.500"
          borderRadius="full"
        />
      )}
    </MotionFlex>
  );
  
};